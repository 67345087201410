import { Box, Button, TextField, Checkbox, FormControlLabel } from "@material-ui/core";
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Formik } from "formik";
import React, { useContext, useState } from "react"; 
import GitHubLogin from 'react-github-login';
import { Link } from "gatsby";
import * as Yup from "yup";
import axios from "axios";
import { API_URL, GAPI_CLIENT_ID, GITHUB_CLIENT_ID } from "../constants/constants";
import { useLocation } from '@reach/router';
import { toast } from "react-toastify";
import { navigate } from 'gatsby';
import GoogleLogin from "react-google-login";
import Layout from "../components/Layout";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { requestHandler } from "../utils/utils";
import TeamContext from "../context/TeamContextProvider";
const styles = require("../styles/login.module.css")

const Login = () => {
    const [passwordShown, setPasswordShown] = useState(false);
    const { userData , setUserData } = useContext(TeamContext);
    const [view, setView] = useState("email");
    const location = useLocation();
    const onSuccess = async (response) => {
        // console.log("Success", response.code)
        callLoginAPI(response, "github")
    }
    const onFailure = error => console.error("onFailure"); 
    const onRequest = () => console.log("onRequest");

    const responseGoogle = async (response: any) => {
        // console.log("responseGoogle", response); 
        const profileObj = response?.profileObj;
        if (profileObj != null) {
            const { email, googleId, name } = profileObj;
            callLoginAPI(profileObj, "google")
        }
    };

    const callDevAPI = async (access_token) => {
        const result = await requestHandler(API_URL + "developer/" , null, { "Content-Type": "application/json" , "access_token": access_token}, "GET")
        if(result.status === "success") 
        {
            // toast.success(result.message); 
            const payload = {
                "name": result.data.user_name, 
                "email": result.data.email, 
                "team_id": result.data.teams[0].team.team_id, 
                "teamName": result.data.teams[0].team.name
            }
            setUserData(JSON.stringify(payload));
            navigate('/user/apps');
        }
        if(result.status === "failed") 
        {
        }
        if(result === "login") {
            toast.error("Not authenticated. Please login to continue"); 
            navigate("/login");
        }
    }

    const callLoginAPI = async (values : any = {}, type : string = "") => {
        try {
            let data; 
            if(type === "email") {
                data = {
                    "email" : values.email, 
                    "password" : values.password,
                    "login_type": "email"
                }
            }
            else if(type === "github") {
                data = {
                    "github_account_id" : values.code,
                    "login_type": "github"
                }
            }
            else if(type === "google") {
                data = {
                    "google_account_id" : values.googleId,
                    "email": values.email,
                    "login_type": "google"
                }
            }
            const result:any = await axios.post(API_URL + "developer/login", data)
            if(result.data.status === "success") 
            {
                callDevAPI(result.data.data.access_token);
                toast.success(result.data.message); 
                localStorage.setItem("isLoggedIn", "true");
                localStorage.setItem("access_token", result.data.data.access_token);
                localStorage.setItem("refresh_token", result.data.data.refresh_token);
            }
            if(result.status === "failed") 
            {
                toast.error(result.errorMessage); 
            }
                
        }
        catch (error) {
            // Sentry.captureException(error);
            toast.error(error.response.data.error); 
            console.log("error", error.response);
        }
    }

    
    let icon:any ;
    if (passwordShown ==true) {
        icon = <Visibility style = {{cursor: "pointer"}} onClick={() => togglePasswordVisiblity()} />;
    } else if (passwordShown == false) {
        icon = <VisibilityOff style = {{cursor: "pointer"}}  onClick={() => togglePasswordVisiblity()} />;
    }

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    return(
        <Layout>
            <div className = {styles.cls_LoginPageWrap}>
                <div className = {styles.cls_LoginPage}>
                    <div className = {styles.cls_LoginHeader}>
                        <div className = {styles.cls_LoginHeaderLogo}>
                            <img
                            src="https://d199xmsg3owom4.cloudfront.net/images/smitch_logo_white.png"
                            alt="SMITCH logo" height="80" width="80" />
                        </div>
                        <div className = {styles.cls_LoginTitle}>Sign in to your Smitch Developer Account</div>
                    </div>
                    {
                        view === "email" && 
                        <div className = "cls_loginBodyCont">
                            <Formik
                                initialValues={{
                                    password: "",
                                    email: ""
                                }}
                                validationSchema={Yup.object().shape({
                                    email: Yup.string()
                                        .email("Must be a valid email")
                                        .max(255)
                                        .required("Email is required"),
                                    password: Yup.string()
                                        .max(255)
                                        .required("Password is required"),
                                })}
                                onSubmit={async (values: any) => {
                                    callLoginAPI(values, "email");
                                }}>

                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                    values,
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div>
                                            <TextField
                                                error={Boolean(touched.email && errors.email)}
                                                helperText={touched.email && errors.email}
                                                fullWidth
                                                className = "cls_LoginInputs"
                                                label="Email Address"
                                                margin="normal"
                                                name="email"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="email"
                                                value={values.email}
                                                variant = "standard"
                                                inputProps={{
                                                    className: "cls_LoginInputBox"
                                                }}
                                            />
                                            <TextField
                                                error={Boolean(touched.password && errors.password)}
                                                fullWidth
                                                helperText={touched.password && errors.password}
                                                label="Password"
                                                margin="normal"
                                                name="password"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type={passwordShown ? "text" : "password"}
                                                value={values.password}
                                                variant = "standard"
                                                InputProps={{
                                                    endAdornment: icon
                                                }}
                                            />
                                            <div className = {styles.forgotPwdText} onClick = {() => navigate("/forgotPassword")}>Forgot Password?</div>
                                            {/* <FormControlLabel
                                                className = {styles.cls_LoginRemText}
                                                control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon color="primary" />} name="checkedH" />}
                                                label="Remember Me"
                                            /> */}
                                        </div>
                                        <div className = {styles.notaMemberText}>
                                            Dont have an account yet?
                                            <Link to="/signup"  style={{ textDecoration: 'none' }}>
                                                <div className = {styles.signupLinkText}>Create One</div>
                                            </Link>
                                        </div>
                                        <Box my={2} className = {styles.loginBtnWrap}>
                                            <Button
                                                className = {styles.loginBtn}
                                                color="primary"
                                                disabled={isSubmitting}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                            >
                                                Sign In
                                            </Button>
                                        </Box>
                                        
                                    </form>
                                )}
                            </Formik>
                            <div className = {styles.orWrappper} onClick = {() => setView("social")}>
                                <span className = {styles.orText}>
                                    Or Login with Other Methods -{'>'}
                                </span>
                            </div>
                        </div>
                    }
                    {
                        view === "social" && 
                        <>
                        <div className = "cls_SocialLoginWrap">
                            <GoogleLogin
                                clientId={GAPI_CLIENT_ID}
                                buttonText="Login using Google"
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy={'single_host_origin'}
                                render={(props: any) => (
                                    <div className = {styles.socialLogin} onClick={props.onClick}>
                                        <img src = "../Google@3x.png"height="40" width="40" className = {styles.socialLoginImg} />
                                        Login using Google
                                    </div>
                                )}
                            />
                            <GitHubLogin clientId={GITHUB_CLIENT_ID}
                            className={styles.GitHubLogin}
                            valid={true}
                            redirectUri={ location.origin + "/login" }
                            onRequest={onRequest}
                            onSuccess={onSuccess}
                            scope= 'user:email'
                            state="testnowrandom"
                            onFailure={onFailure}>
                                <div className = {styles.socialLogin}>
                                    <img src = "../github.png" height="40" width="40" className = {styles.socialLoginGithubImg}/>
                                    Login using Github
                                </div>
                            </GitHubLogin>
                            {/* <div className = {styles.socialLogin}>
                                <img src = "../Smitch.png" height="40" width="40" className = {styles.socialLoginImg}/>
                                Login using Smitch
                            </div> */}
                        </div>
                        <div className = {styles.orWrappper} onClick = {() => setView("email")}>
                            <span className = {styles.orText}>
                                Or Login with Email -{'>'}
                            </span>
                        </div>
                        </>
                    }
                </div>
            </div>
        </Layout>
    )
}

export default Login;